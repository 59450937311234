$bg-dark:  #112
$bg-light: #eed

$fg-dark:  #eee
$fg-light: #222
    
html, body
    margin: 2.5% 5%
    font-family: "Nunito", "Roboto", "Open Sans", sans-serif
    transition: background ease-in .2s

body.light
    background: $bg-light
    color: $fg-light

    a
        color: #000

        &:hover
            color: #eed
        &:visited
            color: #222


body.dark
    background: $bg-dark
    color: $bg-light

    a
        color: #eed

        &:hover
            color: #fff
        &:visited
            color: #c5c5c5


header
    @for $i from 1 through 6
        h#{$i}
            margin: 0

    h1
        font-size: 3em

    h2
        font-weight: 300
        font-size: 1.25em
        margin-top: -8px

.contents
    width: 100%
    margin-top: 25px

    section
        display: inline-block
        vertical-align: top
        width: 33%
        min-width: 200px
        box-sizing: border-box
        padding: 0 10px

        &.about p
            font-size: .9em
            max-width: 85%

        &.projects
            ul
                list-style: none
                padding: 0

            li h4
                margin-top: 20px
                
            li:first-child h4
                margin-top: 0

            h5, h4
                margin: 0

            h5
                font-weight: 300

            p, a, svg
                font-size: .9em

        &.links ul
            list-style: none
            padding-left: 0

            svg
                font-size: 1.25em
                vertical-align: middle
                width: 17px
                margin-right: 10px

            .flagEmoji
                background: #7a7791
                padding: 0 5px
                font-size: .8em
                border-radius: 2px

body.dark .contents section
    &.links ul .flagEmoji
        background: transparent
        padding: 0

