.themeChanger
    position: relative
    display: inline-block
    vertical-align: top
    text-align: left
    width: 40px

    input
        display: none

    label
        display: block
        overflow: hidden
        cursor: pointer
        border: none
        height: 20px
        border-radius: 5px

        .themeInner
            display: block
            width: 200%
            margin-left: -100%
            transition: margin .3s ease-in 0s

            &:before, &:after
                float: left
                width: 50%
                height: 20px
                padding: 0
                line-height: 20px
                vertical-align: middle
                font-weight: 600
                box-sizing: border-box
                content: ""

            &:before
                padding-left: 10px
                background: #3427a3

            &:after
                padding-right: 10px
                background: #57b8f0
                text-align: right
        
        .themeSwitch
            display: block
            width: 12.5px
            height: 12.5px
            margin: 4px
            background: #e8e9a4
            position: absolute
            top: 0
            bottom: 0
            right: 20px
            border: none
            border-radius: 50%
            transition: all .3s ease-in 0s

    input:checked + label .themeInner
        margin-left: 0

    input:checked + label .themeSwitch
        right: 0
        background: #ddd
